import React, { useEffect, useState } from "react";
import "./ProductDetail.style.css";
import imgSrc from "../../../image/Shoe.jpeg";
import StoreImg from "./StorIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { setAuthentication } from "../../../features/Authentication/AuthenticationSlice";
import { getProductThunk } from "../../../features/Inventory/InventorySlice";
import Loader from "../../../Loader/Loader";
import { convertIntoINR, findByKey } from "../../../utils/helpers";
import Carousel from "../../../components/mobile/Carousel/Carousel";
import SelectAddress from "../../../components/mobile/SelectAddress/SelectAddress";
import { toast } from "react-toastify";

const ProductDetail = () => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState();
  const [variants, setVariants] = useState({});
  const [selectedVariantIndex, setSelectedVariantIndex] = useState(-1);
  const [variantValues, setVariantValues] = useState({});
  const [selectedVariantValue, setSelectedVariantValue] = useState({});
  const [isBuyNowInitiated, setIsBuyNowInitiated] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const streamId = location?.state?.stream_id;

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      fetchProduct();
    }
  }, [params.id]);

  useEffect(() => {
    if (product) {
      populateVariants();
    }
  }, [product]);

  useEffect(() => {
    if (
      product &&
      selectedVariantValue &&
      Object.keys(selectedVariantValue).length
    ) {
      onChangeSelectedVariantValue();
    }
  }, [product, selectedVariantValue]);

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const payload = {
        product_id: params.id,
      };
      const action = await dispatch(getProductThunk(payload));
      if (action.payload?.product) {
        if (action?.payload?.product) {
          let temp = action?.payload?.product;

          if (temp) {
            let tempProductPricingDetails = [];

            temp.productPricingDetails?.forEach((variant, index) => {
              tempProductPricingDetails.push({
                ...variant,
                product_price: parseInt(variant.product_price),
                cost_price: parseInt(variant.product_price),
                selling_price: parseInt(variant.selling_price),
                varient_id: variant.id,
              });
              if (
                variant.id == location?.state?.product_variant_id &&
                variant.variantValueDetails &&
                variant.variantValueDetails.length > 0
              ) {
                let tempSelectedVariantValue = {};
                variant.variantValueDetails.map((variantValue) => {
                  if (variantValue?.varientCategory?.[0]?.varient_name) {
                    tempSelectedVariantValue[
                      variantValue?.varientCategory?.[0]?.varient_name
                    ] = variantValue.id;
                  }
                });
                setSelectedVariantValue(tempSelectedVariantValue);
                setSelectedVariantIndex(index);
              }
            });

            temp["productPricingDetails"] = tempProductPricingDetails;

            setProduct(temp);
          }
        }
        setProduct(action.payload?.product);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const populateVariants = () => {
    let tempVariants = {};
    product?.productPricingDetails?.forEach(
      (variant) =>
        variant.variantValueDetails &&
        variant.variantValueDetails.length > 0 &&
        variant.variantValueDetails.map((variantValue) => {
          if (variantValue?.varientCategory?.[0]?.varient_name) {
            if (
              tempVariants[variantValue?.varientCategory?.[0]?.varient_name]
            ) {
              let ob = findByKey(
                tempVariants[variantValue?.varientCategory?.[0]?.varient_name],
                "variant_value_id",
                variantValue.id
              );

              if (!ob) {
                tempVariants[
                  variantValue?.varientCategory?.[0]?.varient_name
                ].push({
                  name: variantValue.varient_name,
                  value: variantValue.varient_value,
                  variant_value_id: variantValue.id,
                });
              }
            } else {
              tempVariants[variantValue?.varientCategory?.[0]?.varient_name] = [
                {
                  name: variantValue.varient_name,
                  value: variantValue.varient_value,
                  variant_value_id: variantValue.id,
                },
              ];
            }
          } else {
          }
        })
    );

    setVariantValues(tempVariants);
  };

  const onChangeSelectedVariantValue = () => {
    const comparedValues = Object.values(selectedVariantValue);
    product.productPricingDetails?.forEach((variant, index) => {
      let flag =
        variant.variantValueDetails && variant.variantValueDetails.length > 0
          ? true
          : false;

      for (let i = 0; i < variant?.variantValueDetails.length; i++) {
        let variantValue = variant?.variantValueDetails[i];
        if (!variantValue.id || !comparedValues.includes(variantValue.id)) {
          flag = false;
        }
      }

      if (flag) {
        setSelectedVariantIndex(index);
      }
    });
  };

  if (!product) {
    return <Loader />;
  }

  return (
    <div className="product-details">
      <Carousel jumpToIndex={0}>
        {product?.productImageDetails?.map((image, index) => (
          <div className="product-img-container">
            <div key={index}>
              <img
                alt="Thumbnail"
                className="productImage"
                // style={{ width: "100%", height: "10%" }}
                src={image}
              />
            </div>
            ;
          </div>
        ))}
      </Carousel>
      <div className="Product-details-content-container">
        <div className="product-header-container">
          <div className="product-header-left">
            <div className="product-header-title">{product?.product_name}</div>
            <div className="product-header-review">
              {product?.product_description}
            </div>{" "}
          </div>
          <div className="product-header-right">
            {selectedVariantValue &&
            Object.keys(selectedVariantValue).length &&
            selectedVariantIndex != -1 ? (
              <React.Fragment>
                <div className="product-details-price">
                  {parseInt(
                    product?.productPricingDetails?.[selectedVariantIndex]?.[
                      "product_price"
                    ]
                  ) -
                    parseInt(
                      product?.productPricingDetails?.[selectedVariantIndex]?.[
                        "selling_price"
                      ]
                    ) !=
                  0 ? (
                    <div className="product-details-discount-price">
                      ₹
                      {convertIntoINR(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["product_price"]
                      )}
                    </div>
                  ) : (
                    <div />
                  )}

                  <div className="product-details-original-price">
                    ₹
                    {convertIntoINR(
                      product?.productPricingDetails?.[selectedVariantIndex]?.[
                        "selling_price"
                      ]
                    )}
                  </div>
                </div>
                {parseInt(
                  product?.productPricingDetails?.[selectedVariantIndex]?.[
                    "product_price"
                  ]
                ) -
                  parseInt(
                    product?.productPricingDetails?.[selectedVariantIndex]?.[
                      "selling_price"
                    ]
                  ) !=
                0 ? (
                  <div className="product-details-discount-percent">
                    {parseInt(
                      Math.ceil(
                        100 *
                          (parseInt(
                            product?.productPricingDetails?.[
                              selectedVariantIndex
                            ]?.["product_price"]
                          ) -
                            parseInt(
                              product?.productPricingDetails?.[
                                selectedVariantIndex
                              ]?.["selling_price"]
                            ))
                      ) /
                        parseInt(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"]
                        )
                    )}
                    %
                  </div>
                ) : (
                  <div />
                )}
              </React.Fragment>
            ) : (
              <span>Select Variant</span>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            border: "1px #D9D9D9 solid",
            marginTop: "8%",
          }}
        ></div>
        <div className="product-details-seller-details">
          <div className="product-details-seller-details-icon">
            <img
              alt="StoreThumbnail"
              className="StoreImage"
              // style={{ width: "100%", height: "10%" }}
              src={StoreImg}
            />
          </div>
          <div className="product-details-seller-details-content">
            <div className="seller-details-1">{product?.store_name}</div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            border: "1px #D9D9D9 solid",
            marginTop: "8%",
          }}
        ></div>
        {variantValues && Object.keys(variantValues).length > 0 && (
          <div className="product-details-size-box">
            {Object.entries(variantValues).map(
              ([variantCategory, variantCategoryDetail]) => {
                return (
                  <div style={{ marginBottom: "5%" }}>
                    <div className="product-details-size-box-header">
                      <div className="product-details-size-box-size">
                        {variantCategory}
                      </div>
                      <div className="product-details-size-box-size-guide">
                        Guide
                      </div>
                    </div>

                    <div className="product-details-size-selector">
                      <ui className="product-details-size-selector-list">
                        {variantCategoryDetail.map((variantCategoryValue) => (
                          <li
                            style={{
                              display: "flex",

                              padding: "6px 8px",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "8px",
                              borderRadius: "6px",
                              background:
                                selectedVariantValue?.[variantCategory] ==
                                variantCategoryValue.variant_value_id
                                  ? " #1e799c"
                                  : "white",
                              border: `1px solid ${
                                selectedVariantValue?.[variantCategory] ==
                                variantCategoryValue.variant_value_id
                                  ? " #1e799c"
                                  : "var(--Neutral-20, #CECCD3)"
                              }`,
                            }}
                            onClick={() => {
                              setSelectedVariantValue({
                                ...selectedVariantValue,
                                [variantCategory]:
                                  variantCategoryValue.variant_value_id,
                              });
                            }}
                          >
                            <span
                              className="variant-category-name-text"
                              style={{
                                color:
                                  selectedVariantValue?.[variantCategory] ==
                                  variantCategoryValue.variant_value_id
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {variantCategoryValue.name}
                            </span>
                          </li>
                        ))}
                      </ui>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        )}

        <div className="product-detail-container">
          {!location?.state?.hide_buy_now && (
            <button
              className="product-detail"
              disabled={selectedVariantIndex == -1}
              onClick={() => {
                if (streamId) {
                  navigate(-1);
                } else {
                  setIsBuyNowInitiated(true);
                }
              }}
            >
              <span className="product-detail-text">
                {streamId ? "Back to Stream" : "Buy Now"}
              </span>
            </button>
          )}
        </div>

        <SelectAddress
          {...(streamId ? { streamId: streamId } : {})}
          storeId={product.store_id}
          open={isBuyNowInitiated && product ? true : false}
          onClose={() => {
            setIsBuyNowInitiated(false);
          }}
          product={
            selectedVariantIndex != -1 &&
            product?.["productPricingDetails"]?.[selectedVariantIndex]
              ? product?.["productPricingDetails"]?.[selectedVariantIndex]
              : {}
          }
        />
      </div>
    </div>
  );
};

export default ProductDetail;

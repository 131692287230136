import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import "./HslPlayer.style.css";

const HLSPlayer = ({ data }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedMetadata = () => {
        setVideoDimensions({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
        });
      };

      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, []);

  useEffect(() => {
    if (videoRef.current && !isPlaying) {
      // Delay playback to handle autoplay restrictions
      const timer = setTimeout(() => {
        handlePlay();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [videoRef.current, data?.public_url]);

  const handlePlay = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
    }
    if (Hls.isSupported() && data?.public_url) {
      const hls = new Hls();
      hls.loadSource(data.public_url);
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => {
            console.error("Playback error:", error);
          });
      });
    } else if (
      videoElement.canPlayType("application/vnd.apple.mpegurl") &&
      data.public_url
    ) {
      //alert("HLS not supported");
      videoElement.src = data.public_url;
      videoElement.addEventListener("loadedmetadata", function () {
        videoElement
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => {
            console.error("Playback error:", error);
            alert("Playback error:", error);
            //alert("Playback error Add Listener", JSON.stringify(error));
          });
      });
    } else {
      alert("No Fallback");
    }
  };

  return (
    <React.Fragment>
      <div className="hls-container">
        <video
          ref={videoRef}
          className="hls-video "
          controls={false}
          playsInline
        />
      </div>
      {!isPlaying && (
        <div
          onClick={handlePlay}
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            cursor: "pointer",
            zIndex: 10000,
          }}
        >
          <PlayCircleOutlineIcon
            style={{
              color: "#f4f5d7",
              transform: "translate(-50%, -50%)",
              height: "60px",
              width: "60px",
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default HLSPlayer;

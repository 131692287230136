import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Juspay from "../../Juspay/Juspay";
import { useNavigate, useLocation } from "react-router";
import "./ProductCard.style.css";
import BottomSheet from "../BottomSheet/BottomSheet";
import SelectAddress from "../SelectAddress/SelectAddress";

const ProductCard = ({ product, socket, streamId, storeId }) => {
  const [isBuyNowDisabled, setIsBuyNowDisabled] = useState(false);
  const [initiatePayment, setInitiatePayment] = useState(false);
  const [buyNowLoading, setBuyNowLoading] = useState(false);
  const [isSelectAddressOpen, setIsSelectAddressOpen] = useState(false);

  const discount =
    parseInt(product.cost_price) - parseInt(product.selling_price);

  return (
    <div className="product-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10%",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <img
          className="productcard-image"
          src={
            product.image_url && product.image_url[0]
              ? product.image_url[0]
              : "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg"
          }
        />
        <div className="productcard-details">
          <span className="productcard-details-item">
            {product.varient_name}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "end",
            }}
          >
            <span className="productcard-details-item">
              ₹{product.selling_price}{" "}
            </span>
            {discount && discount > 0 ? (
              <React.Fragment>
                <s style={{ color: "white", fontSize: "12px" }}>
                  ₹{product.cost_price}
                </s>
                <span style={{ color: "#02de62", fontSize: "10px" }}>
                  (
                  {(
                    ((parseInt(product.cost_price) -
                      parseInt(product.selling_price)) /
                      parseInt(product.cost_price)) *
                    100
                  ).toFixed(0)}
                  %off){" "}
                </span>
              </React.Fragment>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      {product.varient_count === 0 ? (
        <div className="product-card-sold-out-strip">
          <span style={{ fontSize: "0.8rem" }}>Sold Out</span>
        </div>
      ) : (
        <button
          className="buy-button"
          disabled={product.is_marked_active != 1 || isBuyNowDisabled}
          onClick={() => {
            setIsSelectAddressOpen(true);
            setInitiatePayment(true);
          }}
        >
          <React.Fragment>Buy Now</React.Fragment>
        </button>
      )}

      {product && initiatePayment && (
        <SelectAddress
          streamId={streamId}
          storeId={storeId}
          open={isSelectAddressOpen}
          onClose={() => {
            setIsSelectAddressOpen(false);
          }}
          product={product}
        />
      )}
    </div>
  );
};

export default ProductCard;

import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import useMobileSize from "./hooks/useMobileSize";
import "./App.css";
import DesktopHome from "./pages/Home";
import MobileHome from "./pages/mobile/Home/MobileHome";
import Stream from "./pages/mobile/Stream/Stream";
import Login from "./pages/mobile/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StreamVideo from "./pages/mobile/StreamVideo/StreamVideo";
import PaymentConfirmation from "./pages/mobile/PaymentConfirmation/PaymentConfirmation";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./interceptors/store";
import "promise-polyfill/src/polyfill";
import { useEffect, useState } from "react";
import { getUserThunk } from "./features/User/UserSlice";
import { setAuthentication } from "./features/Authentication/AuthenticationSlice";

import ReactGA from "react-ga4";
import ProductDetail from "./pages/mobile/ProductDetail/ProductDetail";
// Initialize Google Analytics with your Tracking ID
ReactGA.initialize(process.env.REACT_APP_TRACKING_NUMBER);

const AppRoutes = () => {
  const [loading, setLoading] = useState(false);
  const isMobile = useMobileSize(1000);
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector((state) => state.authentication);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      isSignedIn,
      isMobile,
      userId: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : null,
    });
  }, [isSignedIn]);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const action = await dispatch(getUserThunk());
      if (getUserThunk.fulfilled.match(action)) {
        const data = action.payload?.user;

        if (
          data &&
          data.first_name &&
          data.last_name &&
          data.email &&
          data.user_name
        ) {
          localStorage.setItem("username", data?.user_name);
          localStorage.setItem("showChat", true);
          localStorage.setItem("switchMobileView", true);
          if (location.pathname == "/") {
            navigate("/stream"); // Navigation now occurs within the Router context
          }
        }
        setLoading(false);
      } else {
        toast.error("Error fetching user");
        setLoading(false);
        dispatch(setAuthentication(false));
      }
    } catch {
      setLoading(false);
      toast.error("Error fetching user");
      dispatch(setAuthentication(false));
    }
  };

  return (
    <Routes>
      {!isMobile ? (
        <Route
          path="*"
          element={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15%",
              }}
            >
              <div style={styles.messageBox}>
                <h1 style={styles.heading}>
                  Please open this page on a mobile or tablet device
                </h1>
                <p style={styles.subText}>
                  This content is optimized for smaller screens. Try resizing or
                  switching to a mobile device.
                </p>
              </div>
            </div>
          }
        />
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/stream" element={<MobileHome />} />
          <Route path="/stream/:id" element={<Stream />} />
          <Route path="/stream/:id/*" element={<StreamVideo />} />
          <Route path="/payment" element={<PaymentConfirmation />} />
          <Route path="/product/:id" element={<ProductDetail />} />
        </>
      )}
    </Routes>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        className="toast-container"
        style={{ zIndex: 1000000 }}
        limit={1}
        position="top-right"
        autoClose={4000}
        theme="colored"
      />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Provider>
  );
};

export default App;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(135deg, #f8f9fa, #e9ecef)", // Gradient background
  },
  messageBox: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    maxWidth: "500px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#343a40",
    marginBottom: "10px",
  },
  subText: {
    fontSize: "16px",
    color: "#6c757d",
  },
};

const toastCss = {};

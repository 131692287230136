import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import AvatarPic from "../image/avatar.png";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import useMobileSize from "../hooks/useMobileSize";

const HLSPlayer = (data) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const isMobile = useMobileSize();

  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleLoadedMetadata = () => {
      setVideoDimensions({
        width: videoElement.videoWidth,
        height: videoElement.videoHeight,
      });
    };

    videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  const handlePlay = () => {
    if (Hls.isSupported() && data) {
      const hls = new Hls();
      hls.loadSource(data?.data?.public_url);
      hls.attachMedia(videoRef?.current);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        videoRef?.current?.play();
        setIsPlaying(true);
      });
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = data?.data?.public_url;
      videoRef.current.addEventListener("canplay", function () {
        videoRef?.current?.play();
        setIsPlaying(true);
      });
    }
  };

  return (
    <div
      style={{
        width: isMobile ? "85vw" : "600px",
        height: isMobile ? "600px" : "85vh",
        margin: "0 auto",
      }}
    >
      <video
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "24px",
          objectFit: "cover",
        }}
        ref={videoRef}
        controls
      />

      <div
        className="seller-info"
        style={{ position: "absolute", top: "25px", left: "25px" }}
      >
        <img className="seller-avatar" src={AvatarPic} alt="Seller" />
        <span className="seller-name" style={{ color: "#ffffff" }}>
          {data?.seller_name ? data?.seller_name : "Seller name"}
        </span>
      </div>
      {!isPlaying && (
        <PlayCircleOutlineIcon
          onClick={handlePlay}
          style={{
            position: "absolute",
            top: isMobile ? "900px" : "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#ffffff",
            height: "60px",
            width: "60px",
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
};

export default HLSPlayer;

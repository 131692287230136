import React, { useState } from "react";
import { useNavigate } from "react-router";
import ZoopLogo from "../../SvgImages/ZooLogo/ZoopLogo";
import AddAddress from "../AddAddress/AddAddress";
import BottomSheet from "../BottomSheet/BottomSheet";
import SelectAddress from "../SelectAddress/SelectAddress";
import "./Header.style.css";

const Header = () => {
  const [isLogoutBottomSheetOpen, setIsLogoutBottomSheetOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="mobile-home-header">
        <div onClick={() => navigate("/stream")}>
          <ZoopLogo height={100} width={100} />
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            className="logout-button"
            onClick={() => {
              setIsLogoutBottomSheetOpen(true);
            }}
          >
            Logout
          </button>
        </div>
      </div>
      <BottomSheet
        header={"Logout"}
        isOpen={isLogoutBottomSheetOpen}
        onClose={() => {
          setIsLogoutBottomSheetOpen(false);
        }}
      >
        <div className="header-logout-content-container">
          <p style={{ color: "black" }}>Are you sure you want to logout?</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "5%",
            }}
          >
            <div style={{ width: "50%" }}>
              <button
                className="logout-yes-button"
                onClick={() => {
                  localStorage.clear();
                  window.location.href = "/";
                }}
              >
                Yes
              </button>
            </div>
            <div style={{ width: "50%" }}>
              <button
                className="logout-no-button"
                onClick={() => {
                  setIsLogoutBottomSheetOpen(false);
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </React.Fragment>
  );
};

export default Header;

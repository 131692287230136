import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CountdownTimer from "../../../components/mobile/CountdownTimer/CountdownTimer";
import ProductInfo from "../../../components/mobile/ProductInfo/ProductInfo";
import "./LiveStream.style.css";
import { useNavigate } from "react-router";
import SaleProducts from "../SaleProducts/SaleProducts";

const LiveStream = ({
  item,
  onHandleStreamPage,
  showCountdown,
  showSaleProducts,
}) => {
  const [timeLeft, setTimeLeft] = useState("");

  const handleImageError = (e) => {
    e.target.src =
      "https://fastly.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY";
  };

  return (
    <div
      onClick={() => {
        if (onHandleStreamPage) {
          onHandleStreamPage();
        }
      }}
      style={{
        cursor: onHandleStreamPage ? "pointer" : "none",
        maxWidth: "90vw",
      }}
    >
      <div
        style={
          onHandleStreamPage
            ? {}
            : {
                display: "flex",
                justifyContent: "center",
              }
        }
      >
        <div style={onHandleStreamPage ? {} : { width: "90%" }}>
          <img
            alt="Thumbnail"
            className="background-thumbnail"
            style={{ width: "100%", height: "100%" }}
            src={item.thumbnail}
            onError={handleImageError}
          />
        </div>

        <div style={{ display: showCountdown ? "initial" : "none" }}>
          <CountdownTimer
            timeLeft={timeLeft}
            setTimeLeft={setTimeLeft}
            time={item.scheduled_time}
            status={item.status}
          />
        </div>
      </div>
      <ProductInfo
        title={item.title}
        description={item.description}
        status={
          timeLeft == "Live Now"
            ? item.status == "in_progress" || item.status == "url_generated"
              ? "Live"
              : "Stream Ended"
            : "Upcoming"
        }
        sellerName={
          item.seller_store_name ? item.seller_store_name : item.seller_id
        }
        id={item.id}
        scheduledTime={item.scheduled_time}
        showFullDescription={onHandleStreamPage ? false : true}
        sellerImage={item.seller_profile_url}
      />

      {showSaleProducts && (
        <SaleProducts
          products={
            item && item.stream_varient_mapping
              ? item.stream_varient_mapping
              : []
          }
          storeId={item && item.seller_store_id ? item.seller_store_id : ""}
          status={
            timeLeft == "Live Now"
              ? item.status == "in_progress" || item.status == "url_generated"
                ? "Live"
                : "Stream Ended"
              : "Upcoming"
          }
        />
      )}
    </div>
  );
};

export default LiveStream;

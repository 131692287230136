import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../Loader/Loader";
import { sendOtpAuthentication } from "../../../../features/Authentication/AuthenticationApi";
import "./LoginMobile.style.css";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const LoginMobile = ({
  mobileNumber,
  setIsOtpSent,
  setIsEditing,
  setMobileNumber,
  setTimeLeft,
  setMessageId,
}) => {
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const location = useLocation();

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleMobileNumberChange = (event) => {
    // const input = event.target.value.replace(/\D/g, "");
    const input = event.target.value;
    if (input.length == 10) {
      ReactGA.event({
        category: "User", // Represents the category of the event, e.g., "User"
        action: "Mobile Input", // The action performed, e.g., "Mobile Input"
        label: "User Mobile Input", // A label describing the event, e.g., "User Mobile Input"
        value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
        nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
        // Custom dimensions for additional data (assuming these are set up in your GA account)
        dimension1: location.pathname + location.search, // Custom dimension for the page URL
        dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
        dimension3: input, // Custom dimension for the mobile number input
      });
    }
    if (input.length <= 10) {
      setMobileNumber(input);
    }
  };
  const handleNextClick = async () => {
    if (mobileNumber.length === 10) {
      setLoading(true);
      try {
        ReactGA.event({
          category: "User", // Represents the category of the event, e.g., "User"
          action: "SendOtp", // The action performed, e.g., "SendOtp"
          label: "Send OTP", // A label describing the event, e.g., "Send OTP"
          value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
          nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
          // Custom dimensions for additional data (assuming these are set up in your GA account)
          dimension1: location.pathname + location.search, // Custom dimension for the page URL
          dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
          dimension3: "+91", // Custom dimension for the country code
          dimension4: "buyer", // Custom dimension for the user type
          dimension5: mobileNumber, // Custom dimension for the mobile number
        });

        const data = await sendOtpAuthentication({
          country_code: "+91",
          user_type: "buyer",
          mobile: mobileNumber,
        });
        if (data.success) {
          if (data?.data?.message_id) {
            toast.success("OTP sent successfully");
            setIsOtpSent(true);
            setTimeLeft(30);
            setLoading(false);
            setMessageId(data?.data?.message_id);
          } else {
            ReactGA.event({
              category: "Error", // Represents the category of the event, e.g., "Error"
              action: "SendOtpError", // The action performed, e.g., "SendOtpError"
              label: "Send OTP Error", // A label describing the event, e.g., "Send OTP Error"
              value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
              nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
              // Custom dimensions for additional data (assuming these are set up in your GA account)
              dimension1: location.pathname + location.search, // Custom dimension for the page URL
              dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
              dimension3: "+91", // Custom dimension for the country code
              dimension4: "buyer", // Custom dimension for the user type
              dimension5: mobileNumber, // Custom dimension for the mobile number
              dimension6: data, // Custom dimension for any additional data you want to track
            });
            toast.error("Error sending OTP");
          }
        } else {
          ReactGA.event({
            category: "Error", // Represents the category of the event, e.g., "Error"
            action: "SendOtpError", // The action performed, e.g., "SendOtpError"
            label: "Send OTP Error", // A label describing the event, e.g., "Send OTP Error"
            value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
            nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
            // Custom dimensions for additional data (assuming these are set up in your GA account)
            dimension1: location.pathname + location.search, // Custom dimension for the page URL
            dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
            dimension3: "+91", // Custom dimension for the country code
            dimension4: "buyer", // Custom dimension for the user type
            dimension5: mobileNumber, // Custom dimension for the mobile number
            dimension6: data, // Custom dimension for any additional data you want to track
          });
          toast.error("Error sending OTP");
        }
      } catch {}
    } else {
      toast.error("Please enter a valid 10-digit mobile number.");
    }
  };
  return (
    <>
      <ToastContainer />
      {!loading ? (
        <div>
          <div className="loginMobile-container">
            <div className="enter-number-heading">Enter your phone number</div>
            <div className="enter-number-subheading">
              Enter your number to verify with OTP
            </div>
            <input
              // value={`+91${mobileNumber}`}
              value={mobileNumber}
              type="number"
              onChange={handleMobileNumberChange}
              maxLength="13"
              placeholder="Enter Number"
            />
            <div
              style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                marginBottom: "6%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "90%",
                  marginBottom: "4%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "center",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "15%",
                    justifyContent: "flex-start",
                  }}
                  className="checkbox"
                >
                  <input
                    type="checkbox"
                    id="agreement"
                    style={{
                      height: "2.5vh",
                    }}
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div
                  style={{
                    width: "90%",
                    textAlign: "left",
                  }}
                >
                  <span className="footer-text">
                    I agree with the{" "}
                    <a
                      className="term-and-condition-text"
                      href="https://storage.googleapis.com/zoop-public-bucket/Terms%20of%20Use-Buyer%20App%2016July.pdf"
                      target="_blank"
                    >
                      Terms of Use,
                    </a>
                    {"        "}
                    <a
                      className="term-and-condition-text"
                      href="https://storage.googleapis.com/zoop-public-bucket/PRIVACY%20POLICY-Buyer%20App.pdf"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      className="term-and-condition-text"
                      href="https://storage.googleapis.com/zoop-public-bucket/Shipping%20%26%20Returns%20Policy%2016July.pdf"
                      target="_blank"
                    >
                      Shipping & Returns Policy
                    </a>{" "}
                  </span>
                </div>
              </div>

              <button
                style={{
                  marginBottom: "2%",
                  backgroundColor:
                    mobileNumber.length === 10 && isCheckboxChecked
                      ? "#1e799c"
                      : "#EDEEF2",
                  color:
                    mobileNumber.length === 10 && isCheckboxChecked
                      ? "#FFFFFF"
                      : "#9EA2B3",
                }}
                onClick={() => {
                  handleNextClick();
                  setIsEditing(false);
                }}
                disabled={!isCheckboxChecked || !mobileNumber.length == 10}
              >
                Next
              </button>
              <div className="footer-text-container">
                <span className="footer-text">
                  Registered Address: M-202, Pioneer Park, Golf Course Extn.
                  Road, Gurgaon, 122001 For any support, please email us at :{" "}
                  <a
                    className="term-and-condition-text"
                    target="_blank"
                    href="help@zooplive.com"
                  >
                    help@zooplive.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default LoginMobile;

import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./ShopProducts.style.css";
import { useNavigate } from "react-router";
import { convertIntoINR } from "../../../utils/helpers";
import BottomSheet from "../BottomSheet/BottomSheet";
import ReadMore from "../../ReadMore/ReadMore";
import SelectAddress from "../SelectAddress/SelectAddress";

const ShopProduct = ({ product, type, onClickBuyNow, streamId }) => {
  const navigate = useNavigate();

  const discount =
    parseInt(product.cost_price) - parseInt(product.selling_price);

  return (
    <React.Fragment>
      <div className="shop-product-container">
        <div className="shop-products-body">
          <div className="shop-product-item">
            <div className="shop-product-image-wrapper">
              {product.varient_count === 0 && (
                <div className="sold-out-strip">
                  <span>Sold Out</span>
                </div>
              )}
              <img
                src={
                  product.image_url && product.image_url[0]
                    ? product.image_url[0]
                    : ""
                }
                className={`shop-product-image${
                  product.varient_count === 0 ? " sold-out-image" : ""
                }`}
              />
            </div>
          </div>
          <div className="shop-product-item">
            <div className="shop-product-item-header">
              <h5 className="shop-product-title">{product.varient_name}</h5>
              <h5 className="shop-product-subtitle">
                {product.varient_count}{" "}
                {product.varient_count == 1 ? "piece" : "pieces"} left
              </h5>
            </div>
            <div className="shop-product-item-body">
              <span className="shop-product-selling-price">
                {discount && discount > 0 ? (
                  <React.Fragment>
                    <span className="shop-product-cost-price">
                      ₹{convertIntoINR(product.cost_price)}
                    </span>{" "}
                  </React.Fragment>
                ) : (
                  <div />
                )}
                ₹{convertIntoINR(product.selling_price)}
              </span>

              {discount && discount > 0 ? (
                <React.Fragment>
                  <span className="shop-product-discount">
                    {((discount / product.cost_price) * 100).toFixed(0)}% off
                  </span>
                </React.Fragment>
              ) : (
                <div />
              )}
            </div>

            {type == "buyNow" && (
              <div style={{ marginTop: "10%", marginBottom: "5%" }}>
                <div className="shop-product-item-footer">
                  {product.varient_count != 0 ? (
                    <button
                      className="shop-product-button-container"
                      onClick={() => {
                        onClickBuyNow(product);
                      }}
                      style={{ marginRight: "5%" }}
                    >
                      <span className="shop-product-button-text">Buy Now</span>
                    </button>
                  ) : (
                    <div />
                  )}

                  <button
                    className="shop-product-secondary-button-container"
                    onClick={() => {
                      navigate(`/product/${product.product_id}`, {
                        state: {
                          product_variant_id: product.varient_id,
                          stream_id: streamId,
                        },
                      });
                    }}
                  >
                    <span className="shop-product-secondary-button-text">
                      View Product
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ShopProducts = ({ open, onClose, products, storeId, streamId }) => {
  const upcomingProducts = products.filter(
    (product) => product.is_marked_active === 0
  );
  const buyNowProducts = products.filter(
    (product) => product.is_marked_active === 1
  );
  const [isSelectAddressOpen, setIsSelectAddressOpen] = useState(false);
  const [selectedBuyNowProduct, setSelectedBuyNowProduct] = useState();

  useEffect(() => {
    if (isSelectAddressOpen) {
      onClose();
    }
  }, [isSelectAddressOpen]);

  return (
    <React.Fragment>
      <BottomSheet
        header={"Shop"}
        isOpen={open}
        onClose={() => {
          onClose();
        }}
      >
        <div className="shop-products-container">
          {upcomingProducts && upcomingProducts.length != 0 && (
            <div className="shop-products-upcoming-products-container">
              <h5 className="shop-upcoming-products-title">Upcoming</h5>
              <hr style={{ border: "1px solid #F8D28F" }} />
              <div className="shop-products-upcoming-products-content-container">
                <ReadMore
                  items={upcomingProducts}
                  contractTitle={"See less"}
                  expandTitle={"See more"}
                >
                  {(visibleProducts) =>
                    visibleProducts.map((product) => (
                      <ShopProduct
                        key={product.id}
                        product={product}
                        storeId={storeId}
                        type="upcoming"
                      />
                    ))
                  }
                </ReadMore>
              </div>
            </div>
          )}

          {buyNowProducts && buyNowProducts.length != 0 && (
            <div className="shop-products-buy-now-products-container">
              <h5 className="shop-products-title">Buy Now</h5>
              <hr style={{ border: "1px solid #E5E7EB" }} />
              <div className="shop-products-buy-now-products-content-container">
                {buyNowProducts.map((product) => (
                  <ShopProduct
                    product={product}
                    storeId={storeId}
                    type="buyNow"
                    onClose={onClose}
                    streamId={streamId}
                    onClickBuyNow={(selectedProduct) => {
                      setSelectedBuyNowProduct(selectedProduct);
                      setIsSelectAddressOpen(true);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </BottomSheet>
      {selectedBuyNowProduct && (
        <SelectAddress
          streamId={streamId}
          storeId={storeId}
          open={isSelectAddressOpen}
          onClose={() => {
            setIsSelectAddressOpen(false);
          }}
          product={selectedBuyNowProduct}
        />
      )}
    </React.Fragment>
  );
};

export default ShopProducts;

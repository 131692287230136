import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductInfo.style.css"; // Add your profile picture here
import SellerInfo from "../SellerInfo";
import { formatTimestamp } from "../../../utils/helpers";

const ProductInfo = ({
  title,
  description,
  status,
  disabled,
  onEnterStream,
  sellerName,
  scheduledTime,
  showFullDescription,
  sellerImage,
}) => {
  const handleEnterStream = () => {
    if (onEnterStream) {
      onEnterStream();
    }
  };

  return (
    <div>
      <div
        className="mobile-product-details-container"
        style={{
          background:
            status == "Live"
              ? "#EF4444"
              : status == "Upcoming"
              ? "#3897f1"
              : "#111827",
        }}
      >
        <span className="mobile-product-details-status-title">{status}</span>
      </div>

      <div className="mobile-product-details-seller-container">
        <SellerInfo image={sellerImage} name={sellerName} />
      </div>

      <div className="mobile-product-details">
        <h2 className="mobile-product-name">{title}</h2>
        <h2
          className="mobile-product-description"
          style={{ WebkitLineClamp: showFullDescription ? 100 : 2 }}
        >
          {description}
        </h2>

        <h2 className="mobile-product-stream-time">
          {formatTimestamp(scheduledTime)}{" "}
        </h2>
      </div>
    </div>
  );
};

export default ProductInfo;

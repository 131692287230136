import { api, apiConfig } from "../../interceptors/api";

/**
 * @typedef {Object} sendOtpAuthenticationPayload
 * @property {string}  country_code
 * @property {string} user_type
 * @property {number} mobile
 */
/**
 * @typedef {Object} sendOtpResponse
 * @property {boolean} success
 * @property {number} statusCode
 * @property {Object} data
 * @property {string} message
 */
/**
 * @param {sendOtpAuthenticationPayload} payload
 * @returns {Promise<sendOtpResponse>}
 */

export const sendOtpAuthentication = async (payload) => {
  try {
    const response = await api.post(
      `${apiConfig.authUrl}/api/auth/v2/otp`,
      payload
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
    throw new Error("Failed to send otp");
    // Add more API functions as needed
  }
};

export const verifyOtpAuthentication = async (payload) => {
  try {
    const response = await api.post(
      `${apiConfig.authUrl}/api/auth/v2/otp/verify`,
      payload
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
    throw new Error("Failed to send otp");
  }
};

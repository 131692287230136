import React from "react";
import "./CustomRadio.style.css"; // Make sure to include the CSS file

const CustomRadio = ({
  label,
  value,
  name,
  checked,
  onChange,
  radioWidth = null,
  radioHeight = null,
}) => {
  return (
    <label className="custom-radio">
      <input
        type="radio"
        value={value}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span
        className="radio-mark"
        style={{ width: radioWidth, height: radioHeight }}
      ></span>
      {label}
    </label>
  );
};

export default CustomRadio;

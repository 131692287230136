import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../Loader/Loader";
import { useNavigate } from "react-router";
import "./UserDetails.style.css";
import { updateUserData } from "../../../../features/User/UserApi";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
const UserDetails = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastName") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      if (user.first_name) {
        setFirstName(user.first_name);
      }
      if (user.last_name) {
        setLastName(user.last_name);
      }
      if (user.user_name) {
        setUsername(user.user_name);
      }
      if (user.email) {
        setEmail(user.email);
      }
    }
  }, [user]);

  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
  };

  const handleChangeFirstName = (event) => {
    const firstName = event.target.value;
    setFirstName(firstName);
  };

  const handleChangeLastName = (event) => {
    const lastName = event.target.value;
    setLastName(lastName);
  };

  const handleChangeEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const validatePayload = (payload) => {
    const { first_name, last_name, email, user_name } = payload;

    // Check if all fields are filled
    if (!first_name || !last_name || !email || !user_name) {
      return { isValid: false, message: "All fields are required." };
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return { isValid: false, message: "Invalid email format." };
    }

    const usernameRegex = /^\S{3,}$/;
    if (!usernameRegex.test(user_name)) {
      return {
        isValid: false,
        message:
          "Invalid username format. Must be at least 3 characters and contain no spaces.",
      };
    }

    // All validations passed
    return { isValid: true };
  };

  const handleUsernameSubmit = async () => {
    if (username?.trim() !== "" && firstName && lastName && email) {
      //check if payload regex is valid or not
      let payload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        user_name: username,
      };

      // Validate the payload
      const validation = validatePayload(payload);

      if (!validation.isValid) {
        ReactGA.event({
          category: "User", // The category of the event, e.g., "User"
          action: "UpdateUserDetailsError", // The action performed, e.g., "UpdateUserDetailsError"
          label: "Validation Error", // A label describing the event, e.g., "Validation Error"
          value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
          nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
          // You can send additional data like `page` and `userId` as custom dimensions if needed
          dimension1: location.pathname + location.search, // Custom dimension for the page
          dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
          dimension3: validation.message, // Custom dimension for the validation error message
        });
        toast.error(validation.message);
        return;
      }

      setLoading(true);
      try {
        ReactGA.event({
          category: "User", // The category of the event, e.g., "User"
          action: "UpdateUserDetails", // The action performed, e.g., "UpdateUserDetails"
          label: location.pathname + location.search, // Optional: a label for additional context, like the page URL
          value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
          nonInteraction: true, // Optional: set to true if this event should not affect the bounce rate
          // You can include additional data like `userId` as custom dimensions if needed
          dimension1: localStorage.getItem("userId") || null, // Custom dimension for the user ID
          dimension2: payload, // Custom dimension for the payload if necessary
        });
        const data = await updateUserData({
          user_info: payload,
        });

        console.log("data", data);

        if (data.success) {
          toast.success("User information updated successfully");
          localStorage.setItem("username", username);
          localStorage.setItem("showChat", true);
          localStorage.setItem("switchMobileView", true);
          setLoading(false);
          navigate("/stream");
        } else {
          ReactGA.event({
            category: "User", // Represents the category of the event, e.g., "User"
            action: "UpdateUserDetailsError", // Represents the specific action performed, e.g., "UpdateUserDetailsError"
            label: "Api Error", // A label describing the event, e.g., "Api Error"
            value: 1, // Optional: a numerical value for the event, such as 1 for occurrence
            nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
            // Custom dimensions for additional data (assuming these are set up in your GA account)
            dimension1: location.pathname + location.search, // Custom dimension for the page URL
            dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
            dimension3: JSON.stringify(payload), // Custom dimension for the payload, stringify if needed
            dimension4: JSON.stringify(data), // Custom dimension for the data, stringify if needed
          });
          toast.error("Error updating user information");
          setLoading(false);
        }
      } catch {
        toast.error("Error.");
      }
    } else {
      toast.error("Please fill all fields.");
    }
  };

  return (
    <>
      <ToastContainer />
      {!loading ? (
        <div className="user-details-centeredContainer">
          <div className="user-details-joinChatContainerss">
            <div className="user-details-joinChatHeading">
              Enter your details
            </div>
            <div className="user-details-joinChatSubHeading">
              This will be used for live chat
            </div>
            <div
              style={{
                display: "flex", // : "flex",
                flexDirection: "row",
                gap: "5%",
              }}
            >
              <div className="user-details-input-wrapper">
                <label className="user-details-input-label"> First Name</label>
                <input
                  style={{
                    width: "90%",
                    marginLeft: "0",
                    paddingLeft: "6%",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={handleChangeFirstName}
                />
              </div>
              <div className="user-details-input-wrapper">
                <label className="user-details-input-label" htmlFor="name">
                  Last Name
                </label>
                <input
                  style={{
                    width: "90%",
                    marginLeft: "0",
                    paddingLeft: "6%",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={handleChangeLastName}
                />
              </div>
            </div>
            <div className="user-details-input-wrapper">
              <label className="user-details-input-label" htmlFor="name">
                Email
              </label>
              <input
                className="user-details-input"
                type="email"
                id="name"
                name="name"
                placeholder="Enter Email"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
            <div className="user-details-input-wrapper">
              <label className="user-details-input-label" htmlFor="name">
                Username
              </label>
              <input
                className="user-details-input"
                type="text"
                placeholder="Enter Username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div
              style={{
                position: "fixed",
                bottom: 0,
                width: "95%",
                marginBottom: "6%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <button onClick={handleUsernameSubmit}>Next</button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserDetails;

import { api, apiConfig } from "../../interceptors/api";

export const initiateBuyNowApi = async (payload) => {
  try {
    const response = await api.post(
      `${apiConfig.cartUrl}/api/cart/buyNow`,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to update cart"
    );
  }
};

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Home.style.css";
import { useNavigate } from "react-router";
import { getLiveStreams } from "../../../features/LiveStream/LiveStreamApi";
import Carousel from "../../../components/mobile/Carousel/Carousel";
import Loader from "../../../Loader/Loader";
import LiveStream from "../../../components/mobile/LiveStream/LiveStream";
import Header from "../../../components/mobile/Header/Header";
import { setAuthentication } from "../../../features/Authentication/AuthenticationSlice";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const MobileHome = () => {
  const [chatVisible, setChatVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [liveStreams, setLiveStreams] = useState([]);
  const [initialIndex, setInitialIndex] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = () => {
    if (!localStorage.getItem("username")) {
      navigate("/");
    } else {
      dispatch(setAuthentication(true));
      const hasShownToast = localStorage.getItem("hasShownToast");
      if (!hasShownToast) {
        toast.success("You have successfully logged in!");
        localStorage.setItem("hasShownToast", "true");
      }

      fetchLiveStreams();
    }
  };

  const fetchLiveStreams = async () => {
    setLoading(true);
    try {
      const response = await getLiveStreams();

      if (response.data) {
        setLiveStreams(response.data);
        let tempInitialIndex = initialIndex;
        for (let index = 0; index < response.data.length; index++) {
          let item = response.data[index];
          if ((item.status == "open")) {
            tempInitialIndex = index;
          }
          if (item.status == "in_progress" || (item.status == "url_generated")) {
            tempInitialIndex = index;
            console.log("index", index);
            break;
          }
        }
        setInitialIndex(tempInitialIndex);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleChatClick = () => {
    setChatVisible(!chatVisible);
  };

  const handleEnterStreamPage = (streamId) => {
    ReactGA.event({
      category: "User", // Represents the category of the event, e.g., "User"
      action: "Enter Stream 1", // The action performed, e.g., "Mobile Input"
      label:
        "Enter Stream by " + localStorage.getItem("userId") + "on" + streamId, // A label describing the event, e.g., "User Mobile Input"
      value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
      nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
      // Custom dimensions for additional data (assuming these are set up in your GA account)
      dimension1: location.pathname + location.search, // Custom dimension for the page URL
      dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
      dimension3: streamId, // Custom dimension for the mobile number input
    });
    navigate(`/stream/${streamId}`);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="app">
      <div style={{ maxWidth: "1000px", alignSelf: "center" }}>
        <Header />
        <div className="live-streams-container">
          <Carousel jumpToIndex={initialIndex}>
            {liveStreams.map((item) => (
              <div
                className="live-stream-container"
                style={{ display: item.isActive == 1 ? "flex" : "none" }}
              >
                <LiveStream
                  item={item}
                  key={item.id}
                  showCountdown={true}
                  onHandleStreamPage={() => handleEnterStreamPage(item.id)}
                />
              </div>
            ))}
          </Carousel>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default MobileHome;

import React, { useEffect, useRef, useMemo, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { css } from "@emotion/css";
import "./Chat.style.css";
import ScrollToBottom from "react-scroll-to-bottom";
import { toast } from "react-toastify";

const ROOT_CSS = css({
  maxHeight: 300,
  "overflow-y": "scroll",
});

const Chat = ({
  username,
  roomId,
  socket,
  onProductActivated,
  onChangeUserCount,
  onUpdateVariantCount,
  show,
  onConnect,
}) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendMessage = async () => {
    if (currentMessage !== "") {
      socket.emit("sendMessage", currentMessage, (message) => {
        setMessageList((list) => {
          if (list !== null) return [...list];
        });
        setCurrentMessage("");
      });
    }
  };

  useEffect(() => {
    const joinRoom = async () => {
      socket.emit(
        "signin",
        { user: username, room: roomId },
        (err, response) => {
          if (err) {
            console.log(err, "error");
          } else if (response && response.messages) {
            setMessageList(response.messages);
          } else {
            console.log("Unexpected response format", response);
          }
        }
      );
    };
    joinRoom();
  }, [socket, username, roomId]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
      onConnect();
    });

    socket.on("disconnect", (err) => {
      console.log("server disconnected: ", err);
      if (err === "io server disconnect") {
        socket.connect();
      }
    });

    socket.io.on("reconnect", () => {
      console.log("reconnected");
      socket.emit(
        "updateSocketId",
        { user: username, room: roomId },
        (error) => {
          if (error) {
            console.error(error);
          } else {
            onConnect();
          }
        }
      );
    });

    socket.on("ping", () => {
      console.log("Received ping from server");
      socket.emit("pong");
    });

    socket.on("message", (data) => {
      const messageWithTime = {
        ...data,
        time: new Date().toLocaleTimeString(),
      };
      setMessageList((list) => [...list, messageWithTime]);
    });

    socket.on("productActivated", ({ success, productInfo }) => {
      // Check if productInfo is valid and update productActivated
      if (
        productInfo &&
        (Array.isArray(productInfo) || typeof productInfo === "object")
      ) {
        // Convert single object to array if necessary
        const updatedProducts = Array.isArray(productInfo)
          ? productInfo
          : [productInfo];

        onProductActivated(updatedProducts);
      }
    });

    // Listen for notification event
    socket.on("notification", (data) => {
      if (data.for_buyer) {
        toast.success(data.description);
      }
    });

    socket.on("roomUserCount", (data) => {
      onChangeUserCount(data);
    });

    socket.on("updateVarientCount", (productInfo) => {
      if (
        productInfo &&
        (Array.isArray(productInfo) || typeof productInfo === "object")
      ) {
        // Convert single object to array if necessary
        const updatedProducts = Array.isArray(productInfo)
          ? productInfo
          : [productInfo];

        onUpdateVariantCount(updatedProducts);
      }
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("notification");
      socket.off("message");
      socket.off("buyNowResponse");
      socket.off("productActivated");
      socket.off("ping");
      socket.off("updateVarientCount");
      socket.off("roomUserCount");
    };
  }, []);

  return (
    <div className="chat-box" style={{ display: show ? "initial" : "none" }}>
      <div className="chat-message">
        {messageList.map((messageContent, index) => (
          <div className="user-info" key={index} id={"you"}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img src="/profile.jpg" alt="User avatar" className="avatar" />
              <div class="chat-message-history">
                <span className="username" id="author">
                  {messageContent.user}
                  {username === messageContent.user ? "*" : ""}
                </span>
                <span className="message">{messageContent?.text}</span>
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={currentMessage}
          placeholder="Say Hi!"
          onChange={(event) => setCurrentMessage(event.target.value)}
          style={{ color: "white" }}
        />
        <button className="send-button" onClick={sendMessage}>
          <MdArrowForwardIos size={"20px"} />
        </button>
      </div>
    </div>
  );
};
export default Chat;

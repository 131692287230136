import { api, apiConfig } from "../../interceptors/api";

export const getOrdersApi = async (params) => {
  try {
    const response = await api.get(`${apiConfig.orderUrl}/api/order`, {
      params: params,
    });
    return response.data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Failed to fetch order");
  }
};

export const getOrderByOrderIdApi = async (orderId) => {
  try {
    console.log("API", orderId);
    const response = await api.get(`${apiConfig.orderUrl}/api/order`, {
      params: { order_id: orderId },
    });

    console.log("Response", response);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch order by order id");
  }
};

import { useEffect, useRef, useState } from "react";

const usePolling = (callback, delay, maxPolls) => {
  const savedCallback = useRef();
  const [pollCount, setPollCount] = useState(0);
  const [isPolling, setIsPolling] = useState(false);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    if (isPolling && pollCount < maxPolls) {
      const id = setInterval(() => {
        if (savedCallback.current) {
          savedCallback.current();
          setPollCount((prevCount) => prevCount + 1);
        }
      }, delay);

      return () => clearInterval(id);
    }
  }, [isPolling, delay, pollCount, maxPolls]);

  const startPolling = () => {
    setPollCount(0);
    setIsPolling(true);
  };

  const stopPolling = () => {
    setIsPolling(false);
  };

  return { isPolling, startPolling, stopPolling, pollCount };
};

export default usePolling;

import React, { useState, useEffect, useRef } from "react";
import "./Juspay.style.css";
import { initiatePaymentApi } from "../../features/Payment/PaymentApi";
import { initiateBuyNowThunk } from "../../features/Cart/CartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const Juspay = ({
  initiate = false,
  redirectUrl = "/",
  userId,
  streamId,
  addressId,
  amount,
  product,
  storeId,
  deliveryAddress,
  onInitiationComplete,
  onInitiationFailure,
  onCancel,
  type = "buyNow",
}) => {
  const [paymentLink, setPaymentLink] = useState(null);
  const paymentLinkRef = useRef(paymentLink); // Create a ref to store paymentLink
  const dispatch = useDispatch();

  const onBackButtonEvent = (e) => {
    if (paymentLinkRef.current) {
      e.preventDefault();
      window.history.replaceState(null, null, window.location.href);
      setPaymentLink(null);
      onCancel();
    }
  };

  useEffect(() => {
    paymentLinkRef.current = paymentLink; // Keep the ref updated with the latest paymentLink value
  }, [paymentLink]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (initiate) {
      initiatePayments();
    } else {
      setPaymentLink(null);
    }
  }, [initiate]);

  const initiatePayments = async () => {
    try {
      if (type === "buyNow") {
        let payload = {
          product_id: product.product_id,
          product_pricing_details_id: product.varient_id,
          quantity: 1,
          seller_store_id: storeId,
          address_id: addressId,
          delivery_address: deliveryAddress,
          selling_price: amount,
        };

        if (streamId) {
          payload = {
            ...payload,
            stream_id: parseInt(streamId),
          };
        }
        const action = await dispatch(initiateBuyNowThunk(payload));
        const response = action?.payload;
        if (initiateBuyNowThunk.fulfilled.match(action)) {
          if (response && response?.payment_links?.web) {
            localStorage.setItem("redirectUrl", redirectUrl);
            setPaymentLink(response.payment_links.web);
            if (onInitiationComplete) {
              onInitiationComplete();
            }
          }
        } else {
          toast.error(
            action?.payload ??
              "Payment is currently under maintenance. Sorry for the inconvenience"
          );
          if (onInitiationFailure) {
            onInitiationFailure();
          }
        }
      } else {
        const payload = {
          user_id: userId,
          cart_ids: ["3"],
          order_amount: amount,
          cart_delivery_address_id: addressId,
        };

        const response = await initiatePaymentApi(payload);

        if (response?.payment_links?.web) {
          localStorage.setItem("redirectUrl", redirectUrl);
          setPaymentLink(response.payment_links.web);
          if (onInitiationComplete) {
            onInitiationComplete();
          }
        } else if (onInitiationFailure) {
          onInitiationFailure();
        }
      }
    } catch (error) {
      console.error("error", error);
      if (onInitiationFailure) {
        onInitiationFailure();
      }
      toast.error(
        "Payment is currently under maintenance. Sorry for the inconvenience"
      );
    }
  };

  return (
    <React.Fragment>
      {paymentLink && (
        <div id="juspayDiv" className="fullscreen-container">
          <iframe
            src={paymentLink}
            allow="payment *"
            className="fullscreen-iframe"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Juspay;

import React, { useState } from "react";
import "./ReadMore.style.css";

const ReadMore = ({ contractTitle, expandTitle, items, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="read-more-container">
      {children(items.slice(0, isExpanded ? items.length : 1))}
      {items.length > 1 && (
        <div className="read-more-button-container">
          <button onClick={handleToggle} className="read-more-button">
            {isExpanded ? contractTitle : expandTitle}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReadMore;

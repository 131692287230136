import React, { useEffect } from "react";
import "./BottomSheet.style.css";

const BottomSheet = ({ header, isOpen, onClose, children, footer }) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("bottom-sheet-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <div
      className={`bottom-sheet-overlay ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`bottom-sheet ${isOpen ? "open" : ""}`}>
        <div className="bottom-sheet-header">
          <span className="bottom-sheet-header-text">{header}</span>
          <button className="close-button" onClick={onClose}>
            X
          </button>
        </div>
        <div className="bottom-sheet-content">{children}</div>
        <div className="bottom-sheet-footer">{footer}</div>
      </div>
    </div>
  );
};

export default BottomSheet;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Carousel.css";

const Carousel = ({ children, jumpToIndex, carouselStyle = {} }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (
      jumpToIndex !== undefined &&
      jumpToIndex !== null &&
      jumpToIndex >= 0 &&
      jumpToIndex !== currentIndex
    ) {
      setCurrentIndex(jumpToIndex);
    }
  }, [jumpToIndex]);

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % React.Children.count(children)
    );
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + React.Children.count(children)) %
        React.Children.count(children)
    );
  };

  return (
    <div className="carousel" style={carouselStyle}>
      <button onClick={handlePrev} className="carousel-button prev">
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div className="carousel-content">
        {React.Children.map(children, (child, index) => (
          <div
            className={`carousel-item ${
              index === currentIndex ? "active" : ""
            }`}
            style={{ display: index === currentIndex ? "" : "none" }}
          >
            {child}
          </div>
        ))}
      </div>
      <button onClick={handleNext} className="carousel-button next">
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;

import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./SaleProducts.style.css";
import { useNavigate } from "react-router";
import { convertIntoINR } from "../../../utils/helpers";
import SelectAddress from "../SelectAddress/SelectAddress";

const SaleProduct = ({ product, storeId, status, streamId }) => {
  const [isSelectAddressOpen, setIsSelectAddressOpen] = useState(false);
  const [initiateBuyNow, setInitiateButNow] = useState(false);
  const discount =
    parseInt(product.cost_price) - parseInt(product.selling_price);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="sale-product-container">
        <div className="sale-products-body">
          <div className="sale-product-item">
            <img
              src={
                product.image_url && product.image_url[0]
                  ? product.image_url[0]
                  : ""
              }
              className="sale-product-image"
            />
          </div>
          <div className="sale-product-item">
            <div className="sale-product-item-header">
              <h5 className="sale-product-title">{product.varient_name}</h5>
              <h5 className="sale-product-subtitle">
                {product.varient_count}{" "}
                {product.varient_count == 1 ? "piece" : "pieces"} left
              </h5>
            </div>
            <div className="sale-product-item-body">
              <span className="sale-product-selling-price">
                {discount && discount > 0 ? (
                  <React.Fragment>
                    <span className="sale-product-cost-price">
                      ₹{convertIntoINR(product.cost_price)}
                    </span>{" "}
                  </React.Fragment>
                ) : (
                  <div />
                )}
                ₹{convertIntoINR(product.selling_price)}
              </span>

              {discount && discount > 0 ? (
                <React.Fragment>
                  <span className="sale-product-discount">
                    {((discount / product.cost_price) * 100).toFixed(0)}% off
                  </span>
                </React.Fragment>
              ) : (
                <div />
              )}
            </div>

            <div className="sale-product-item-footer">
              {false && (
                <button
                  className="sale-product-button-container"
                  onClick={() => {
                    setIsSelectAddressOpen(true);
                    setInitiateButNow(true);
                  }}
                >
                  <span className="sale-product-button-text">Buy Now</span>
                </button>
              )}

              <button
                className="sale-product-secondary-button-container"
                onClick={() => {
                  navigate(`/product/${product.product_id}`, {
                    state: {
                      product_variant_id: product.varient_id,
                      hide_buy_now: status == "Stream Ended" ? false : true,
                    },
                  });
                }}
              >
                <span className="sale-product-secondary-button-text">
                  View Product
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {initiateBuyNow && (
        <SelectAddress
          storeId={storeId}
          open={isSelectAddressOpen}
          onClose={() => {
            setIsSelectAddressOpen(false);
          }}
          product={product}
        />
      )}
    </React.Fragment>
  );
};

const SaleProducts = ({ products, storeId, status }) => {
  return (
    <div className="sale-products-container">
      <h5 className="sale-products-title">Products for sale</h5>
      <hr style={{ border: "1px solid #CECCD3" }} />
      {products.map((product) => (
        <SaleProduct product={product} storeId={storeId} status={status} />
      ))}
    </div>
  );
};

export default SaleProducts;

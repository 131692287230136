const moment = require("moment");

export const logoutUser = async () => {
  localStorage.clear();
};

/**
 *
 * @param array
 * @param key
 * @param value
 * @returns
 */
export const findIndexByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] == value) {
      return i;
    }
  }
  return -1;
};

/**
 *
 * @param {*} timestamp
 * @returns
 */
export const formatTimestamp = (timestamp) => {
  const date = moment(timestamp);
  const formattedDate = date.format("dddd DD MMMM, h:mm A");
  return formattedDate;
};

/**
 * Method to convert number into Indian Rupees.
 * @param {*} number
 */
export const convertIntoINR = (number) => {
  if (!number) {
    return number ? number.toString() : ""; // Assuming the input number is stringified if falsy
  }

  number = parseFloat(number.toString());

  const ans = number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return ans.substring(1);
};

export const getAddressStringFromObject = (address) => {
  return `${address?.address_details_line1}, ${address?.address_details_line2}, ${address?.city}, ${address?.pincode}, ${address?.state}`;
};

export const findByKey = (array, key, value) => {
  for (const element of array) {
    if (element[key] == value) {
      return element;
    }
  }
  return null;
};

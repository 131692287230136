import AvatarPic from "../../../src/image/avatar.png";
import "./ProductInfo/ProductInfo.style.css";

const SellerInfo = ({ image = null, name }) => {
  return (
    <div className="seller-info">
      <img
        src={image ? image : AvatarPic}
        alt={AvatarPic}
        className="profile-pic"
      />
      <span className="seller-name">{name}</span>
    </div>
  );
};

export default SellerInfo;

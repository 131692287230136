import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProduct } from "./InventoryApi";

const initialState = {
  loading: false,
  error: null,
  success: false,
  products: [],
};

export const getProductThunk = createAsyncThunk(
  "inventory/getProduct",
  async (params, { rejectWithValue }) => {
    try {
      const response = await getProduct(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProductThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getProductThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
export const { reset } = userSlice.actions;

import React, { useState, useEffect, useRef, useMemo } from "react";
import "./Stream.style.css";
import { useNavigate, useParams } from "react-router";
import Loader from "../../../Loader/Loader";
import { getLiveStreamProducts } from "../../../features/LiveStream/LiveStreamApi";
import LiveStream from "../../../components/mobile/LiveStream/LiveStream";
import Header from "../../../components/mobile/Header/Header";
import { useDispatch } from "react-redux";
import { setAuthentication } from "../../../features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
const label = { inputProps: { "aria-label": "Switch demo" } };

const Stream = () => {
  const [stream, setStream] = useState({});
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      fetchStreamProducts();
    }
  }, [params.id]);

  const fetchStreamProducts = async () => {
    setLoading(true);
    try {
      const payload = {
        stream_id: params.id,
      };
      const response = await getLiveStreamProducts(payload);
      if (response.data && response.data[0]) {
        const data = response.data[0];
        setStream(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleEnterStream = () => {
    ReactGA.event({
      category: "User", // Represents the category of the event, e.g., "User"
      action: "Stream Clicked", // The action performed, e.g., "Mobile Input"
      label:
        "Enter Stream by " + localStorage.getItem("userId") + "on" + stream.id, // A label describing the event, e.g., "User Mobile Input"
      value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
      nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
      // Custom dimensions for additional data (assuming these are set up in your GA account)
      dimension1: location.pathname + location.search, // Custom dimension for the page URL
      dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
      dimension3: stream.id, // Custom dimension for the mobile number input
      dimension4: stream, // Custom dimension for the mobile number input
    });
    navigate(`/stream/${stream.id}/live`);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mobile-live-stream-container">
      <div style={{ maxWidth: "1000px", alignSelf: "center" }}>
        <Header />
        <LiveStream
          item={stream}
          showSaleProducts={true}
          showCountdown={false}
        />
        {stream &&
          (stream.status == "in_progress" ||
            stream.status == "url_generated") && (
            <div className="enter-stream-button-container">
              <button
                className="enter-stream-button"
                onClick={handleEnterStream}
              >
                <span className="enter-stream-button-text">Enter Stream</span>
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default Stream;

import React, { useEffect, useState } from "react";
import moment from "moment";
import "./CountdownTimer.style.css";
import BottomSheet from "../BottomSheet/BottomSheet";
import { useNavigate } from "react-router";

const CountdownTimer = ({ timeLeft, setTimeLeft, time, status }) => {
  useEffect(() => {
    const updateTimer = () => {
      const currentTime = moment();
      const scheduledTime = moment(time);
      const duration = moment.duration(scheduledTime.diff(currentTime));

      if (
        duration.asSeconds() > 0 &&
        (status == "open" || status == "url_generated")
      ) {
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.minutes());
        const seconds = Math.floor(duration.seconds());

        // Format timeLeft string
        setTimeLeft(`${hours}:${minutes}:${seconds}`);
      } else {
        setTimeLeft("Live Now");
      }
    };

    updateTimer(); // initial call
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval); // cleanup on unmount
  }, [time]);

  return (
    <React.Fragment>
      {timeLeft == "Live Now" ? (
        <div />
      ) : (
        <div className="countdown-container">
          <div className="countdown-timer">
            <span>
              Starts in <span className="timer-count">{timeLeft}</span>
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CountdownTimer;

import { api, apiConfig } from "../../interceptors/api";

export const getUserData = async () => {
  try {
    const response = await api.get(`${apiConfig.userUrl}/api/user/buyer`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};

export const updateUserData = async (payload) => {
  try {
    const respose = await api.patch(
      `${apiConfig.userUrl}/api/user/update_buyer_details`,
      payload
    );
    return respose.data;
  } catch (error) {
    throw new Error("Failed to update user info");
  }
};

export const getCityStateByPincode = async (payload) => {
  try {
    const respose = await api.get(
      `${apiConfig.userUrl}/api/user/pincode/${payload.pincode}`
    );
    return respose.data;
  } catch (error) {
    throw new Error("Failed to fetch pincode data");
  }
};

export const getStoreFrontDetailsApi = async (storeId, type) => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/store/store_front`,
      {
        params: { store_id: storeId, type },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch store front details");
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addAddressThunk,
  getCityStateByPincodeThunk,
} from "../../../features/User/UserSlice";
import BottomSheet from "../BottomSheet/BottomSheet";
import { toast } from "react-toastify";
import "./AddAddress.style.css";

const Footer = ({ onClose, onSubmit, loading, disabled }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "3%",
        width: window.innerWidth > 700 ? "94%" : "90%",
        padding: "0.5rem 1.25rem 0.5rem 1.25rem",
        boxShadow: "0px -4px 20px 0px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        background: "white",
        maxWidth: "1000px",
      }}
    >
      <div className="add-address-action-wrapper">
        <button
          className="add-address-negative-button "
          onClick={() => {
            onClose();
          }}
        >
          Discard
        </button>
      </div>
      <div className="add-address-action-wrapper">
        <button
          className="add-address-positive-button"
          loading={loading}
          onClick={() => {
            onSubmit();
          }}
          disabled={disabled}
        >
          {loading ? (
            <React.Fragment>
              <div className="payment-spinner" />
            </React.Fragment>
          ) : (
            <span>Save</span>
          )}
        </button>
      </div>
    </div>
  );
};

const AddAddress = ({ open, onClose, onSuccess, addressDisabled }) => {
  const [deliveryAddress, setDeliveryAddress] = useState({
    address_line_1: "",
    address_line_2: "",
    pincode: "",
    state: "",
    city: "",
    save_as: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    checkDisability();
  }, [deliveryAddress]);

  useEffect(() => {
    if (
      deliveryAddress &&
      deliveryAddress.pincode &&
      deliveryAddress.pincode.toString().length == 6
    ) {
      fetchCityState();
    }
  }, [deliveryAddress.pincode]);

  const checkDisability = () => {
    let flag = false;
    for (let key in deliveryAddress) {
      if (!deliveryAddress[key]) {
        flag = true;
        break;
      } else if (
        key == "pincode" &&
        deliveryAddress[key].toString().length != 6
      ) {
        flag = true;
        break;
      }
    }

    setDisabled(flag);
  };

  const onChange = (event, id) => {
    let tempUpdate = {
      [id]: event.target.value,
    };

    if (
      id == "pincode" &&
      event.target.value &&
      event.target.value.toString().length != 6
    ) {
      tempUpdate = {
        ...tempUpdate,
        city: "",
        state: "",
      };
    }

    setDeliveryAddress((prev) => ({
      ...prev,
      ...tempUpdate,
    }));
  };

  const fetchCityState = async () => {
    try {
      const payload = {
        pincode: deliveryAddress?.pincode,
      };
      const action = await dispatch(getCityStateByPincodeThunk(payload));
      if (getCityStateByPincodeThunk.fulfilled.match(action)) {
        const data = action.payload;
        if (data && data[0]) {
          setDeliveryAddress((prev) => ({
            ...prev,
            city: data[0].city,
            state: data[0].state,
          }));
        } else {
          toast.error("Invalid pincode");
        }
      } else {
        toast.error("Invalid pincode");
      }
    } catch {
      toast.error("Error while validating pincode");
    }
  };

  const onSubmit = async () => {
    const payload = {
      user_address_details: {
        address_name: deliveryAddress.save_as,
        address_details_line1: deliveryAddress.address_line_1,
        address_details_line2: deliveryAddress.address_line_2,
        pincode: deliveryAddress.pincode,
        city: deliveryAddress.city,
        state: deliveryAddress.state,
        country: "india",
        address_type: "DELIVERY",
      },
    };

    setLoading(true);
    try {
      const action = await dispatch(addAddressThunk(payload));
      if (addAddressThunk.fulfilled.match(action)) {
        onSuccess();
      } else {
        //failure
        toast.error(`Error while adding address`);
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  console.log("adres", deliveryAddress);

  return (
    <BottomSheet
      header={"Add new address"}
      isOpen={open}
      onClose={onClose}
      footer={
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          loading={loading}
          disabled={disabled || addressDisabled}
        />
      }
    >
      <div className="add-address-container">
        <div className="add-address-input-container">
          <div className="add-address-input-wrapper">
            <label className="add-address-input-label">Address line 1</label>
            <input
              className="add-address-input"
              type="text"
              id="address_line_1"
              name="address_line_1"
              placeholder="Address line 1"
              value={deliveryAddress?.address_line_1}
              onChange={(event) => onChange(event, "address_line_1")}
            />
          </div>
          <div className="add-address-input-wrapper">
            <label className="add-address-input-label">Address line 2</label>
            <input
              className="add-address-input"
              type="text"
              id="address_line_2"
              name="address_line_2"
              placeholder="Address line 2"
              value={deliveryAddress?.address_line_2}
              onChange={(event) => onChange(event, "address_line_2")}
            />
          </div>
          <div className="add-address-input-wrapper">
            <label className="add-address-input-label">Pincode</label>
            <input
              className="add-address-input"
              type="text"
              id="pincode"
              name="pincode"
              placeholder="Pincode"
              value={deliveryAddress?.pincode}
              onChange={(event) => onChange(event, "pincode")}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "93%",
            }}
          >
            <div className="add-address-input-wrapper" style={{ width: "48%" }}>
              <label className="add-address-input-label">State</label>
              <input
                className="add-address-input"
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={deliveryAddress?.state}
                onChange={(event) => onChange(event, "state")}
              />
            </div>
            <div className="add-address-input-wrapper" style={{ width: "48%" }}>
              <label className="add-address-input-label">City</label>
              <input
                className="add-address-input"
                type="text"
                id="city"
                name="city"
                placeholder="City"
                value={deliveryAddress?.city}
                onChange={(event) => onChange(event, "city")}
              />
            </div>
          </div>

          <div className="add-address-input-wrapper">
            <label className="add-address-input-label">Save as</label>
            <input
              className="add-address-input"
              type="text"
              id="save_as"
              name="save_as"
              placeholder="Save as"
              value={deliveryAddress?.save_as}
              onChange={(event) => onChange(event, "save_as")}
            />
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default AddAddress;

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router";
import "./PaymentConfirmation.style.css";
import Loader from "../../../Loader/Loader";
import usePolling from "../../../hooks/usePooling";
import {
  getBuyerOrderByOrderId,
  getOrderByOrderIdApi,
} from "../../../features/Order/OrderApi";
import { useDispatch } from "react-redux";
import {
  getOrderByOrderIdThunk,
  getOrdersThunk,
} from "../../../features/Order/OrderSlice";
import SuccessSvg from "../../../components/SvgImages/SuccessSvg/SuccessSvg";
import FailureSvg from "../../../components/SvgImages/FailureSvg/FailureSvg";
import useTimer from "../../../hooks/useTime";

let fetchOrderByOrderId;

const PaymentConfirmation = () => {
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [orderFound, setOrderFound] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isPolling, startPolling, stopPolling, pollCount } = usePolling(
    fetchOrderByOrderId,
    5000,
    10
  );

  const { time, resetTimer } = useTimer({
    // stopTimer, resetTimer
    initialTime: !loading ? 5 : 100,
    interval: 1000,
    onTick: (currentTime) => {
      //console.log(currentTime);
    },
    onFinish: () => {
      onRedirection();
    },
  });

  useEffect(() => {
    fetchParams();
    onPageLoad();
  }, []);

  useEffect(() => {
    if (orderId) {
      startPolling();
    }
  }, [orderId]);

  useEffect(() => {
    if (!loading) {
      resetTimer();
    }
  }, [loading]);

  fetchOrderByOrderId = async () => {
    try {
      let params = {
        order_ref_id: orderId,
      };
      const action = await dispatch(getOrdersThunk(params));
      const response = action?.payload;

      if (
        response.data &&
        response.data?.orders &&
        response?.data?.orders.length > 0
      ) {
        stopPolling();
        setLoading(false);
        setOrderFound(true);
      } else {
        if (pollCount == 5) {
          stopPolling();
          setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onPageLoad = () => {
    if (!localStorage.getItem("username")) {
      window.location.replace("/");
    }
  };

  const fetchParams = () => {
    const params = new URLSearchParams(location.search);
    let tempOrderId = params.get("order_id") ?? "21212323";
    let tempStatus = params.get("status");

    if (tempOrderId) {
      setOrderId(tempOrderId);
    }

    if (tempStatus) {
      setPaymentStatus(tempStatus);
    }
  };

  const onRedirection = () => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      window.location.replace("/stream");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="payment-confirmation-container">
      {orderFound ? (
        <div className="payment-confirmation-inner-container ">
          <SuccessSvg width={"20vw"} height={"20vh"} />
          <span className="payment-confirmation-title-text">
            Payment Successful
          </span>
          <span className="payment-confirmation-body-text">
            Thank you for patronizing us today. We value you!
          </span>
          <button
            className="payment-confirmation-continue-shop-button"
            onClick={onRedirection}
          >
            <span className="payment-confirmation-continue-shop-button-text">
              Continue shopping
            </span>
          </button>
          <span className="payment-confirmation-redirect-text">
            Redirecting in {time} second{time <= 1 ? "" : "s"}
          </span>
        </div>
      ) : (
        <div className="payment-confirmation-inner-container ">
          <FailureSvg width={"20vw"} height={"20vh"} />
          <span className="payment-confirmation-title-text">
            Something went Wrong
          </span>
          <span className="payment-confirmation-body-text">
            Please contact support if amount has been deducted
          </span>
          <span className="payment-confirmation-redirect-text">
            Redirecting in {time} second{time <= 1 ? "" : "s"}
          </span>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default PaymentConfirmation;

import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../Loader/Loader";
import "./Otp.style.css";
import {
  sendOtpAuthentication,
  verifyOtpAuthentication,
} from "../../../../features/Authentication/AuthenticationApi";
import { getUserData } from "../../../../features/User/UserApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useTimer from "../../../../hooks/useTime";
import { getUserThunk } from "../../../../features/User/UserSlice";
import {
  getSendOtpAuthenticationThunk,
  getVerifyOtpAuthenticationThunk,
} from "../../../../features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const OtpVerification = ({
  mobileNumber,
  timeLeft,
  setIsEditing,
  setUserId,
  setIsOtpSent,
  setIsOtpVerified,
  setMobileNumber,
  messageId,
  setMessageId,
}) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token") || ""); // Retrieve token from localStorage
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const [otp, setOtp] = useState("");

  const otpInputs = useRef([]);

  const navigate = useNavigate();

  const { time, resetTimer } = useTimer({
    // stopTimer, resetTimer
    initialTime: 30,
    interval: 1000,
    onTick: (currentTime) => {
      console.log(currentTime);
    },
    onFinish: () => {
      console.log("time Finisged");
    },
  });

  const handleVerifyOtp = async () => {
    if (otp.length === 6) {
      setLoading(true);
      try {
        ReactGA.event({
          category: "User", // The category of the event, e.g., "User"
          action: "VerifyOtp", // The action performed, e.g., "VerifyOtp"
          label: "Verify OTP Attempt", // A label describing the event, e.g., "Verify OTP Attempt"
          value: 1, // Optional: a numerical value for the event, such as 1 to count occurrences
          nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
          // Custom dimensions for additional data (assuming these are set up in your GA account)
          dimension1: location.pathname + location.search, // Custom dimension for the page URL
          dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
          dimension3: mobileNumber, // Custom dimension for the mobile number
          dimension4: "+91", // Custom dimension for the country code
          dimension5: "buyer", // Custom dimension for the user type
          dimension6: otp, // Custom dimension for the OTP
        });
        const action = await dispatch(
          getVerifyOtpAuthenticationThunk({
            country_code: "+91",
            user_type: "buyer",
            mobile: mobileNumber,
            otp,
            message_id: messageId,
          })
        );
        if (getVerifyOtpAuthenticationThunk.fulfilled.match(action)) {
          toast.success("OTP verified successfully");
          const data = action.payload;
          console.log("Data", data);
          const receivedToken = data?.data?.token;
          const userId = data?.data?.user_id;
          setUserId(userId);
          setToken(receivedToken); // Store the token in state
          //fetchUser();
          setLoading(false);
          setIsOtpVerified(true);
        } else {
          ReactGA.event({
            category: "User", // The category of the event, e.g., "User"
            action: "VerifyOtpError", // The action performed, e.g., "VerifyOtpError"
            label: "Verify OTP Error", // A label describing the event, e.g., "Verify OTP Error"
            value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
            nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
            // Custom dimensions for additional data (assuming these are set up in your GA account)
            dimension1: location.pathname + location.search, // Custom dimension for the page URL
            dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
            dimension3: mobileNumber, // Custom dimension for the mobile number
            dimension4: "+91", // Custom dimension for the country code
            dimension5: "buyer", // Custom dimension for the user type
            dimension6: otp, // Custom dimension for the OTP
          });
          toast.error("Error verifying OTP");
          setLoading(false);
        }
      } catch {
        ReactGA.event({
          category: "User", // Represents the category of the event, e.g., "User"
          action: "InvalidOtpError", // The action performed, e.g., "InvalidOtpError"
          label: "Invalid OTP Error", // A label describing the event, e.g., "Invalid OTP Error"
          value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
          nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
          // Custom dimensions for additional data (assuming these are set up in your GA account)
          dimension1: location.pathname + location.search, // Custom dimension for the page URL
          dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
          dimension3: mobileNumber, // Custom dimension for the mobile number
          dimension4: "+91", // Custom dimension for the country code
          dimension5: "buyer", // Custom dimension for the user type
          dimension6: otp, // Custom dimension for the OTP
        });

        setLoading(false);
        toast.error("Invalid OTP");
      }
    } else {
      toast.error("Please enter a valid 6-digit OTP.");
      setLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setLoading(true);
    try {
      ReactGA.event({
        category: "User", // Represents the category of the event, e.g., "User"
        action: "ResendOtp", // The action performed, e.g., "ResendOtp"
        label: "Resend OTP", // A label describing the event, e.g., "Resend OTP"
        value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
        nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
        // Custom dimensions for additional data (assuming these are set up in your GA account)
        dimension1: location.pathname + location.search, // Custom dimension for the page URL
        dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
        dimension3: mobileNumber, // Custom dimension for the mobile number
        dimension4: "+91", // Custom dimension for the country code
        dimension5: "buyer", // Custom dimension for the user type
      });
      const action = await dispatch(
        getSendOtpAuthenticationThunk({
          country_code: "+91",
          user_type: "buyer",
          mobile: mobileNumber,
        })
      );

      if (getSendOtpAuthenticationThunk.fulfilled.match(action)) {
        if (action?.payload?.data?.message_id) {
          setMessageId(action?.payload?.data?.message_id);
          toast.success("OTP resent successfully");
          resetTimer(30);
          setLoading(false);
        } else {
          ReactGA.event({
            category: "User", // Represents the category of the event, e.g., "User"
            action: "ResendOtpError", // The action performed, e.g., "ResendOtpError"
            label: "Resend OTP Error", // A label describing the event, e.g., "Resend OTP Error"
            value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
            nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
            // Custom dimensions for additional data (assuming these are set up in your GA account)
            dimension1: location.pathname + location.search, // Custom dimension for the page URL
            dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
            dimension3: mobileNumber, // Custom dimension for the mobile number
            dimension4: "+91", // Custom dimension for the country code
            dimension5: "buyer", // Custom dimension for the user type
          });

          toast.error("Error resending OTP");
        }
      } else {
        ReactGA.event({
          category: "User", // Represents the category of the event, e.g., "User"
          action: "ResendOtpError", // The action performed, e.g., "ResendOtpError"
          label: "Resend OTP Error", // A label describing the event, e.g., "Resend OTP Error"
          value: 1, // Optional: a numerical value for the event, e.g., 1 to count occurrences
          nonInteraction: true, // Optional: set to true if you don't want this event to affect bounce rate
          // Custom dimensions for additional data (assuming these are set up in your GA account)
          dimension1: location.pathname + location.search, // Custom dimension for the page URL
          dimension2: localStorage.getItem("userId") || null, // Custom dimension for the user ID
          dimension3: mobileNumber, // Custom dimension for the mobile number
          dimension4: "+91", // Custom dimension for the country code
          dimension5: "buyer", // Custom dimension for the user type
        });

        toast.error("Error resending OTP");
      }
    } catch {}
  };
  const handleOtpChange = (index, value) => {
    const digits = value.replace(/\D/g, "").split("");

    if (digits.length > 0) {
      const newOtp = [...otp];
      let currentIndex = index;

      // Distribute the digits across the OTP inputs
      digits.forEach((digit) => {
        if (currentIndex < 6) {
          newOtp[currentIndex] = digit;
          otpInputs.current[currentIndex].value = digit;
          currentIndex++;
        }
      });

      setOtp(newOtp.join(""));

      // Focus on the next input if there are more characters to input
      if (currentIndex < 6) {
        otpInputs.current[currentIndex].focus();
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {!loading ? (
        <div className="otp-joinChatContainer">
          <div className="otp-joinChatHeading">Enter the OTP</div>
          <div className="otp-joinChatSubHeading">
            We’ve sent you the verification code
          </div>
          <div className="otp-joinChatSubHeading">
            +91 {mobileNumber}
            <span
              style={{
                color: "#1E799C",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
              onClick={() => {
                setIsEditing(true);
                setMobileNumber(mobileNumber);
                setIsOtpSent(false);
              }}
            >
              Edit
            </span>
          </div>
          <div className="otpInputContainer" style={{ display: "flex" }}>
            {[...Array(6)].map((_, index) => (
              <input
                key={index}
                ref={(el) => (otpInputs.current[index] = el)}
                type="number"
                maxLength="1"
                className="otpInput"
                onChange={(e) => handleOtpChange(index, e.target.value)}
              />
            ))}
          </div>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              marginBottom: "6%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginBottom: "3%",
              }}
            >
              {time <= 0 ? (
                <div
                  className="otp-joinChatSubHeading"
                  style={{ color: "#1E799C", fontWeight: "bold" }}
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </div>
              ) : (
                <div className="otp-joinChatSubHeading">
                  Waiting for OTP ({time})
                </div>
              )}
            </div>

            <button
              id="submit-button"
              style={{
                backgroundColor: otp.length === 6 ? "#1E799C" : "#EDEEF2",
                color: otp.length === 6 ? "#FFFFFF" : "#9EA2B3",
              }}
              onClick={handleVerifyOtp}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OtpVerification;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStoreFrontDetailsThunk,
  setSelectedAddressThunk,
} from "../../../features/User/UserSlice";
import BottomSheet from "../BottomSheet/BottomSheet";
import { toast } from "react-toastify";
import "./SelectAddress.style.css";
import CustomRadio from "../../CustomRadio/CustomRadio";
import AddAddress from "../AddAddress/AddAddress";
import OrderDetails from "../OrderDetails/OrderDetails";
import { initiateBuyNowThunk } from "../../../features/Cart/CartSlice";
import { findByKey, getAddressStringFromObject } from "../../../utils/helpers";
import Juspay from "../../Juspay/Juspay";
import { useLocation, useNavigate } from "react-router";
import Loader from "../../../Loader/Loader";

const AddressLabel = ({ addressName, deliveryAddress }) => {
  return (
    <div className="address-label-container">
      <div>
        <span className="address-label-header-text ">{addressName}</span>
      </div>
      <div>
        <span className="address-label-body-text">{deliveryAddress}</span>
      </div>
    </div>
  );
};

const Footer = ({
  product,
  storeId,
  deliveryCharge,
  onClose,
  onPaymentInitiate,
  loading,
}) => {
  const { user, selectedAddress } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    onPaymentInitiate();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: "3%",
        width: window.innerWidth > 700 ? "94%" : "90%",
        padding: "0.5rem 1.25rem 0.5rem 1.25rem",
        boxShadow: "0px -4px 20px 0px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        background: "white",
        maxWidth: "1000px",
      }}
    >
      <div className="select-address-action-wrapper">
        <button
          className="select-address-negative-button"
          onClick={() => {
            onClose();
          }}
        >
          Add new address
        </button>
      </div>
      <div className="select-address-action-wrapper">
        <button
          className="select-address-positive-button"
          loading={loading}
          onClick={() => {
            onSubmit();
          }}
          disabled={selectedAddress ? false : true}
        >
          {loading ? (
            <React.Fragment>
              <div className="payment-spinner" />
            </React.Fragment>
          ) : (
            <span>Pay (₹{product.selling_price + deliveryCharge})</span>
          )}
        </button>
      </div>
    </div>
  );
};

const SelectAddress = ({ storeId, open, onClose, product, streamId }) => {
  const [isOpenAddAddress, setIsAddAddressOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { user, selectedAddress } = useSelector((state) => state.user);
  const [storeDetails, setStoreDetails] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [initiatePayment, setInitiatePayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  useEffect(() => {
    if (user && user.address && user.address.length == 0) {
      onClose();
      setIsAddAddressOpen(true);
    }
  }, [user?.address, open]);

  useEffect(() => {
    if (storeId) {
      getstoreDetails();
    }
  }, [storeId]);

  useEffect(() => {
    if (storeDetails && product?.selling_price) {
      calculateDeliveryCharges();
    }
  }, [storeDetails, product?.selling_price]);

  useEffect(() => {
    if (selectedAddress) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedAddress]);

  const handleRadioChange = (e) => {
    console.log("onChange", e.target.value);
    dispatch(setSelectedAddressThunk({ addressId: e.target.value }));
  };

  const getstoreDetails = async () => {
    try {
      const action = await dispatch(
        getStoreFrontDetailsThunk({
          storeId: storeId,
          type: "details",
        })
      );
      const response = action?.payload;
      if (getStoreFrontDetailsThunk.fulfilled.match(action)) {
        // success
        if (response && response.data) {
          setStoreDetails(response.data);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const calculateDeliveryCharges = () => {
    if (!storeDetails) {
      return;
    }

    const deliveryDiscount = storeDetails.delivery_discount
      ? parseInt(storeDetails.delivery_discount.toString())
      : 0;
    const freeDeliveryLowerLimit = storeDetails.free_delivery_amount
      ? parseInt(storeDetails.free_delivery_amount.toString())
      : 1000000000;
    const genericDeliveryFee = storeDetails.generic_delivery_fee
      ? parseInt(storeDetails.generic_delivery_fee.toString())
      : 0;
    let tempDeliveryCharge = 0;

    if (product?.selling_price) {
      if (
        parseInt(product?.selling_price.toString()) < freeDeliveryLowerLimit
      ) {
        tempDeliveryCharge = genericDeliveryFee - deliveryDiscount;
      }
    }

    setDeliveryCharge(tempDeliveryCharge);
  };

  return (
    <React.Fragment>
      <BottomSheet
        header={"Select address"}
        isOpen={open}
        onClose={onClose}
        footer={
          paymentLoading || !initiatePayment ? (
            <Footer
              product={product}
              storeId={storeId}
              deliveryCharge={deliveryCharge}
              loading={paymentLoading}
              onClose={() => {
                onClose();
                setIsAddAddressOpen(true);
              }}
              onPaymentInitiate={(data) => {
                setInitiatePayment(true);
                setPaymentLoading(true);
              }}
            />
          ) : (
            <div />
          )
        }
      >
        <div className="select-address-container">
          <div className="select-address-input-container">
            {user &&
              user.address.map((item, index) => (
                <div key={index}>
                  <CustomRadio
                    label={
                      <AddressLabel
                        addressName={item?.address_name}
                        deliveryAddress={getAddressStringFromObject(item)}
                      />
                    }
                    value={item?.id}
                    name="address"
                    checked={selectedAddress?.id == item?.id}
                    onChange={handleRadioChange}
                    radioWidth="25px"
                  />
                </div>
              ))}

            <OrderDetails
              quantity={1}
              price={product?.cost_price}
              sellingPrice={product?.selling_price}
              discount={
                parseInt(product?.cost_price) - parseInt(product?.selling_price)
              }
              deliveryCharge={deliveryCharge}
            />
          </div>
        </div>
      </BottomSheet>
      {isOpenAddAddress && (
        <AddAddress
          open={isOpenAddAddress}
          onClose={() => {
            setIsAddAddressOpen(false);
          }}
          onSuccess={() => {
            //setIsAddAddressOpen(false);
            setPaymentLoading(true);
            setInitiatePayment(true);
          }}
          addressDisabled={paymentLoading}
        />
      )}
      {initiatePayment && (
        <Juspay
          initiate={initiatePayment}
          redirectUrl={currentUrl}
          streamId={streamId}
          product={product}
          addressId={selectedAddress?.id}
          storeId={storeId}
          deliveryAddress={getAddressStringFromObject(selectedAddress)}
          amount={parseInt(product.selling_price) + parseInt(deliveryCharge)}
          onInitiationComplete={() => {
            setPaymentLoading(false);
            setIsAddAddressOpen(false);
          }}
          onInitiationFailure={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
          }}
          onCancel={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
            onClose();
          }}
        />
      )}

      {paymentLoading && <Loader />}
    </React.Fragment>
  );
};

export default SelectAddress;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { initiateBuyNowApi } from "./CartApi";

const initialState = {
  loading: false,
  error: null,
  success: false,
  cartDetails: [],
};

export const initiateBuyNowThunk = createAsyncThunk(
  "cart/addProduct",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await initiateBuyNowApi(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateBuyNowThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(initiateBuyNowThunk.fulfilled, (state, action) => {
        state.loading = false;

        state.error = null;
      })
      .addCase(initiateBuyNowThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});

export default cartSlice.reducer;
export const { reset } = cartSlice.actions;

import { useState, useEffect } from "react";

let timer; // eslint-disable-line no-use-before-define
const useTimer = ({ initialTime, interval, onTick, onFinish }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const tick = () => {
      setTime((prevTime) => {
        const currentTime = prevTime - interval / 1000;

        if (onTick) {
          onTick(currentTime);
        }

        if (currentTime <= 0) {
          stopTimer();
          if (onFinish) {
            onFinish();
          }
          clearInterval(timer);
          return 0;
        }

        return currentTime;
      });
    };

    timer = setInterval(tick, interval);

    return () => {
      clearInterval(timer);
    };
  }, [initialTime]);

  const stopTimer = () => {
    setTime(0);
  };

  const resetTimer = () => {
    setTime(initialTime);
  };

  return { time, stopTimer, resetTimer };
};

export default useTimer;

import React, { useEffect, useState } from "react";
import LoginMobile from "../../components/mobile/Login/LoginMobile/LoginMobile";
import OtpVerification from "../../components/mobile/Login/Otp/Otp";
import UserDetails from "../../components/mobile/Login/UserDetails/UserDetails";
import { useNavigate } from "react-router";
import ZoopLogo from "../../components/SvgImages/ZooLogo/ZoopLogo";
import { setAuthentication } from "../../features/Authentication/AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(
    localStorage.getItem("isOtpVerified") === "true"
  );
  const [isEditing, setIsEditing] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [messageId, setMessageId] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("userId") || "");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "7%",
        }}
        className="BrandHeading"
      >
        <ZoopLogo height={52} width={114} />
      </div>
      {isOtpVerified ? (
        <UserDetails />
      ) : isOtpSent ? (
        <OtpVerification
          mobileNumber={mobileNumber}
          timeLeft={timeLeft}
          setIsOtpSent={setIsOtpSent}
          setIsOtpVerified={setIsOtpVerified}
          setIsEditing={setIsEditing}
          setUserId={setUserId}
          setMobileNumber={setMobileNumber}
          messageId={messageId}
          setMessageId={setMessageId}
        />
      ) : (
        <LoginMobile
          mobileNumber={mobileNumber}
          setIsOtpSent={setIsOtpSent}
          setIsEditing={setIsEditing}
          setMobileNumber={setMobileNumber}
          setTimeLeft={setTimeLeft}
          setMessageId={setMessageId}
        />
      )}
    </div>
  );
};

export default Login;

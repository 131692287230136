import { api, apiConfig } from "../../interceptors/api";

export const getLiveStreams = async () => {
  try {
    const response = await api.get(
      `${apiConfig.liveStreamUrl}/api/stream-details/form/?order_type=desc&&order_key=id`
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};

export const getLiveStreamProducts = async (payload) => {
  try {
    const response = await api.get(
      `${apiConfig.liveStreamUrl}/api/stream-details/form`,
      {
        params: {
          stream_id: payload.stream_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};

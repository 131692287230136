import { api, apiConfig } from "../../interceptors/api";

export const getProducts = async () => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/public/product`
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};

export const getProduct = async (params) => {
  try {
    const response = await api.get(
      `${apiConfig.inventoryUrl}/api/inventory/product/${params.product_id}`
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch product data");
    // Add more API functions as needed
  }
};

import React, { useState, useEffect } from "react";

const HideChatSvg = ({ height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.4062 4.5776C8.56267 4.73407 8.80262 4.76738 9.00255 4.6725C9.9112 4.24127 10.9275 4 12.0002 4C15.8662 4 19.0002 7.13401 19.0002 11C19.0002 12.0727 18.7589 13.089 18.3277 13.9976C18.2328 14.1975 18.2661 14.4375 18.4226 14.594L19.1773 15.3487C19.4042 15.5755 19.7835 15.5332 19.9352 15.2505C20.6148 13.9846 21.0002 12.5373 21.0002 11C21.0002 6.02944 16.9708 2 12.0002 2C10.4629 2 9.01554 2.38545 7.74963 3.06498C7.46699 3.21669 7.42462 3.59602 7.65145 3.82285L8.4062 4.5776Z"
        fill="white"
      />
      <path
        d="M5.67251 8.00238C5.76739 7.80246 5.73408 7.56251 5.5776 7.40603L4.82286 6.65129C4.59603 6.42446 4.2167 6.46683 4.06498 6.74947C3.38545 8.01538 3 9.46268 3 11C3 13.1635 3.76456 15.1505 5.0369 16.7026L4.94379 18.9643C4.89879 20.0574 6.00133 20.8294 7.0131 20.4133L9.1436 19.537C10.0422 19.8375 11.003 20 12 20C13.5373 20 14.9846 19.6146 16.2505 18.935C16.5332 18.7833 16.5756 18.404 16.3487 18.1771L15.594 17.4224C15.4375 17.2659 15.1975 17.2326 14.9976 17.3275C14.089 17.7587 13.0727 18 12 18C11.1096 18 10.2603 17.8342 9.47954 17.5328L9.2936 17.461C9.1742 17.4149 9.04167 17.4163 8.92331 17.465L6.97421 18.2667L7.05979 16.1878C7.06517 16.0573 7.01924 15.9298 6.93184 15.8327L6.7955 15.6813C5.67855 14.4404 5 12.8006 5 11C5 9.92735 5.24127 8.91103 5.67251 8.00238Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9433 18.9709C18.7465 19.1677 18.4269 19.1659 18.2323 18.967L4.34566 4.76772C4.1541 4.57185 4.15584 4.25829 4.34957 4.06456L5.05663 3.3575C5.25343 3.1607 5.57305 3.16248 5.76765 3.36146L19.6543 17.5607C19.8458 17.7566 19.8441 18.0701 19.6503 18.2638L18.9433 18.9709Z"
        fill="white"
      />
    </svg>
  );
};

export default HideChatSvg;
